
.art-list-container {
    padding: 20px;
    
  }
  
  
  .art-list-title {
    text-align: center;
    font-size: 2.5em;
    color: #4b0082; /* Indigo color */
    margin-bottom: 20px;
  }
  
  .search-container {
    margin-bottom: 20px;
  }
  
  .search-bar {
    padding: 10px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: block;
    font-size: 1em; /* Make text size larger for readability */
  }
  
  .art-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    background-color: #fff;
  }
  
  .art-item {
    margin: 10px;
    width: 200px; /* Default width for larger screens */
    text-align: center;
  }
  
  .art-image {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px;
    cursor: pointer; /* Change cursor to indicate clickable */
    transition: transform 0.2s; /* Smooth transition for hover effect */
  }
  
  .art-image:hover {
    transform: scale(1.05); /* Scale up image on hover */
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    font-size: 1em; /* Adjust font size for better visibility */
  }
  
  .active-page {
    background-color: #007bff; /* Highlight active page */
    color: white;
  }
  
  .image-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-modal-content {
    position: relative;
  }
  
  .modal-image {
    max-width: 90%;
    max-height: 90%;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .art-item {
      width: 100%; /* Full width on smaller screens */
      margin: 10px 0; /* Adjust margin for better spacing */
    }
  
    .art-list-title {
      font-size: 1.5em; /* Smaller title font size */
    }
  
    .search-bar {
      font-size: 1em; /* Larger text for readability */
    }
  
    .pagination button {
      padding: 8px 15px; /* Increase button padding */
      font-size: 0.9em; /* Adjust font size */
    }
  }
  
  @media (max-width: 480px) {
    .art-list-title {
      font-size: 1.2em; /* Even smaller title font size for very small screens */
    }
  
    .search-bar {
      padding: 8px; /* Reduce padding for smaller devices */
    }
  
    .pagination button {
      padding: 5px 10px; /* Smaller button size for very small screens */
      font-size: 0.8em; /* Smaller font size */
    }
  
    .close-btn {
      font-size: 20px; /* Reduce close button size */
    }
  }
  