/* client/src/components/Login.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* On top of other elements */
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%; /* Responsive */
    position: relative; /* Relative for positioning the close button */
}

.close {
    cursor: pointer;
    position: absolute; /* Position it absolutely within the modal */
    top: 15px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    font-size: 24px;
    font-weight: bold;
    color: #888; /* Color for the close icon */
}

.close:hover {
    color: #f00; /* Change color on hover */
}

.input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    transition: border-color 0.3s;
}

.button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
}

.login{
    color: #4CAF50;
}
