.download-page {
  font-family: 'Arial', sans-serif;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.email-section,
.no-links-section {
  margin: 20px 0;
}

.email-input {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.send-btn,
.back-btn,
.download-btn {
  padding: 10px 20px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.send-btn:hover,
.back-btn:hover,
.download-btn:hover {
  background-color: #28a745;
}

.success-message {
  font-size: 18px;
  color: #28a745;
}

.links-list {
  list-style: none;
  padding: 0;
}

.link-item {
  margin: 10px 0;
}

.download-btn {
  background-color: #28a745;
}

.download-btn:hover {
  background-color: #28a745;
}




/* Toolbar for Zoom Buttons */
.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.zoom-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.zoom-btn:hover {
  background-color: #0056b3;
}

/* PDF Viewer Container */
.pdf-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

/* PDF Scrollable Area */
.pdf-container {
  width: 100%;
  height:1000px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Prevent text selection */
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide Print Dialog */
@media print {
  body {
    display: none;
  }
}

/* Disable dragging */
canvas {
  pointer-events: none;
  border: 1px solid #ddd;
  background: white;
}



.pdf-viewer-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.pdf-container {
  width: 100%;
  height: 90vh;
  overflow-y: auto; /* Allow vertical scrolling */
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Prevent text selection */
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* CSS for the black screen blocker */
.screen-blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:#000; /* Black  overlay */
  z-index: 9999; /* Ensure it's on top of the content */
}


