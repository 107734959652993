/* form */
/* Container for the form */
form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between form fields */
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
}


/* Input fields styling */
input[type="text"],
input[type="number"],
input[type="file"]{
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
  width: 100%;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}
.label{
  text-align: left;
}
select {
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
  width: 100%;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}
/* Hover and focus state for input fields */
input[type="text"]:focus,
input[type="number"]:focus,
input[type="file"]:focus
 {
  border-color: #4CAF50; /* Highlight with a green border on focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.3); /* Subtle green glow */
}

/* Button styling */
button {
  padding: 12px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
  transform: translateY(-2px);
}


/* Responsive design */
@media (max-width: 768px) {
  form {
    max-width: 100%;
    padding: 15px;
  }

  input[type="text"],
  input[type="number"],
  input[type="file"]{
    font-size: 0.9rem;
    padding: 10px;
  }

  button {
    font-size: 0.9rem;
    padding: 10px;
  }
}


/* Styling for individual book item */
.book-item {
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Hover effect for book-item */
.book-item:hover {
  transform: scale(1.05); /* Slightly enlarges the book item */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Styling for book title */
.book-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  /* font-family: 'Georgia', serif; */
}

/* Styling for author and price */
.book-item p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 8px;
}

/* Button styling */
.book-item button {
  background-color: #4CAF50; /* Green button */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.book-item button:last-child {
  background-color: #f44336; /* Red delete button */
}

.book-item button:hover {
  background-color: #3e8e41; /* Darker green on hover */
  transform: translateY(-2px);
}

.book-item button:last-child:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Responsive for mobile devices */
@media (max-width: 600px) {
  .book-item {
    padding: 10px;
  }

  .book-item h3 {
    font-size: 1.2rem;
  }

  .book-item p {
    font-size: 0.9rem;
  }

  .book-item button {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
}


/* search bar  */
/* Style for the search input */
.search-input {
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 80%; /* Default width for larger screens */
  max-width: 600px; /* Max width to avoid stretching too much */
  outline: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  margin: 0 auto; /* Center the input horizontally */
  display: block;
  background-color: #f9f9f9;
  margin-top: 30px;
}

/* Focus state for interactive feel */
.search-input:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .search-input {
    width: 90%; /* Slightly wider for smaller devices */
  }
}

@media (max-width: 480px) {
  .search-input {
    width: 95%; /* Almost full width for very small devices */
  }
}

/* end of search bar  */


.add-book-form-container {
  transition: all 0.3s ease;
}

.add-book-button{
  width: 50%;
}

form {  
  transform: translateY(-10px); /* Slide up effect */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

form.show {
  opacity: 1; /* Show when visible */
  transform: translateY(0); /* Reset position */
}


