.slideshow-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 500px;
  overflow: hidden;
  background-color: #f0f0f0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slide-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 1s ease-in-out; /* Smooth transition effect */
}

.slide {
  flex: 0 0 100%; /* Each slide takes up 100% of the container width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slide-image {
  max-width: 15%;
  height: auto;
  object-fit: cover;
  opacity: 0;
  animation: fadeIn 2s forwards; /* Fade-in effect */
}

.slide-content {
  text-align: center;
  padding: 10px;
  color: #000;
  opacity: 0;
  animation: fadeInText 3s forwards;
}

.slide-quote {
  font-size: 1.6rem;
  font-style: italic;
  margin-top: 15px;
}

.slide-art {
  font-size: 1.3rem;
  color: #000;
  font-style: italic;
}

/* Fade-in keyframe animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95); /* Slight zoom-in effect */
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Slight upward motion */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .slide-quote {
      font-size: 1.2rem;
  }

  .slide-art {
      font-size: 1rem;
  }

  .slide-image {
      max-width: 25%; /* Adjust image size for smaller screens */
  }
}
