
.home-page-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 1500px;
  margin: 0 auto;
  
}

/* Title styling */
.home-page-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #4CAF50;
}
.buttonRegister{
  margin-left: 5px;
}

/* List styling */
.top-book-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Each book item styling */
.top-book-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  max-width: 220px;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.top-book-item:hover {
  transform: translateY(-5px);
}

/* Book image styling */
.top-book-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
}

/* Book details */
.top-book-details h3 {
  font-size: 18px;
  margin: 10px 0;
}

.top-book-details p {
  margin: 5px 0;
}

/* Chart container */
.chart-container {
  margin-top: 40px;
  text-align: center;
  width: 100%;
  background-color: #fff;
}

/* Modal styling */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* .image-modal-content {
  position: relative;
  width: 80%;
  max-width: 500px;
  height: auto;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
} */

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive design for smaller devices */
@media (max-width: 768px) {
  .top-book-list {
    flex-direction: column;
    align-items: center;
  }

  .top-book-item {
    max-width: 100%;
  }

  .home-page-title {
    font-size: 20px;
  }
}





/* General Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 400px; /* Adjust the width of the modal */
  max-width: 100%;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.close-btn:hover {
  color: red;
}

/* Style for Login Modal */
.login-modal-content {
  background-color: #f5f5f5; /* Light background for Login */
}

.login-modal-content h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-modal-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.login-modal-content button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.login-modal-content button:hover {
  background-color: #45a049;
}

/* Style for Register Modal */
.register-modal-content {
  background-color: #e3f2fd; /* Light blue background for Register */
}

.register-modal-content h2 {
  text-align: center;
  margin-bottom: 20px;
}

.register-modal-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.register-modal-content button {
  width: 100%;
  padding: 10px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.register-modal-content button:hover {
  background-color: #0b7dda;
}

/* Optional: Add fade-in effect for better UX */
.modal {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
