.cart-container {
    padding: 20px;
  }
  
  .cart-list {
    list-style-type: none;
    padding: 0;
  }

  .continue-shopping-btn {
    margin-right: 10px; /* Adjust the value as needed */
}

.book-link{
display:none}
  
  .cart-item {
    display: flex;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
  }
  
  .cart-book-image {
    width: 100px;
    height: auto;
    margin-right: 15px;
  }
  
  .checkout-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .checkout{
    width: 10%;
  }
  
  .checkout-button:hover {
    background-color: #0056b3;
  }
  
  .Cart{
    color:#0056b3
  }

  .h2{
    color:#28a745;
  }

  .cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .cart-item-image {
    width: 50px; /* Adjust the width as needed */
    height: auto;
    margin-right: 10px; /* Space between image and text */
  }
  
  .cart-item-details {
    flex: 1; /* Take the remaining space */
  }
  
  


  