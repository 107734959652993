/* Overall wrapper style */
.auth-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f4f7; /* Light background color */
  }
  
  /* Inner container for the form */
  .auth-inner {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  /* Form header */
  h3 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Style for form inputs */
  .form-control {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Focused input field style */
  .form-control:focus {
    border-color: #4caf50;
    outline: none;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }
  
  /* Submit button style */
  .btn-primary {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
  
  .btn-primary:hover {
    background-color: #4CAF50;
  }
  
  /* Forgot password link style */
  .forgot-password {
    text-align: right;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .forgot-password a {
    color: #4CAF50;
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  
  /* Radio button styles for user type (Admin/User) */
  input[type="radio"] {
    margin-right: 10px;
  }
  
  .mb-3 {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
    color: #333;
  }
  