/* Container for the form */
form{
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between form fields */
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 40px;
  }
  
  /* Input fields styling */
  input[type="text"],
  input[type="number"],
  input[type="file"] {
    padding: 12px 15px;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
    width: 97%;
    transition: border 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Button styling */
  button {
    padding: 12px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  .buttonA{
    background-color: red;
    margin-left: 10px;
  }
  
  button:hover {
    background-color: #45a049; /* Slightly darker green on hover */
    transform: translateY(-2px);
  }
  
  /* Container for the art items */
  .art-list {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
    gap: 15px; /* Space between art items */
    margin-top: 20px; /* Space above the art list */
    background-color: #fff;
    
  }
  
  /* Styling for individual art item */
  .art-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(17% - 15px); /* Adjust width to fit 4 items per row */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
    text-align: center; /* Center content within the art item */
    
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .art-item {
      width: calc(50% - 15px); /* 2 items per row on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .art-item {
      width: calc(100% - 15px); /* 1 item per row on very small screens */
    }
  }
  
  /* Image styling */
  .art-item img {
    max-width: 100%; /* Ensures images are responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 4px; /* Rounded corners */
  }
  