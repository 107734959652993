/* BookList.css */

.book-list-container {
  padding: 20px;
  background-color: #f0f8ff; /* Light blue background */
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

.book-list-title {
  text-align: center;
  font-size: 2.5em;
  color: #4b0082; /* Indigo color */
  margin-bottom: 20px;
}

.artwork{
  margin-left: 10px;
  width: 90%;
  font-size: 20px;
  font-weight: bold;
  background-color: #000000;
}
.logout{
  margin-bottom: 10px;
}


/* Grid layout for books */
.book-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 books per row */
  gap: 20px; /* Space between books */
  padding: 0;
  list-style: none;
}

.book-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  text-align: center;
  transition: transform 0.3s;
}

.book-item:hover {
  transform: scale(1.05); /* Enlarge book on hover */
}

.book-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}

.book-details {
  margin-top: 10px;
}

.book-details h3 {
  color: #4b0082;
  font-size: 1.5em;
  margin-bottom: 5px;
}

.book-details p {
  margin: 5px 0;
  font-size: 1.1em;
}

.purchase-btn {
  background-color: #ff6347; /* Tomato color */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.purchase-btn:hover {
  background-color: #ff4500; /* Darker tomato */
}

.notification {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}


@media (max-width: 1200px) {
  .book-list {
    grid-template-columns: repeat(3, 1fr); /* 3 books per row */
  }
}

@media (max-width: 800px) {
  .book-list {
    grid-template-columns: repeat(2, 1fr); /* 2 books per row */
  }
}

@media (max-width: 500px) {
  .book-list {
    grid-template-columns: 1fr; /* 1 book per row on small screens */
  }
}


/* search start  */
/* Search Bar Container */
.search-container {
  display: flex;
  justify-content: center; /* Centers the search bar horizontally */
  margin: 20px 0; /* Adds space above and below the search bar */
}

/* Search Bar Styles */
.search-bar {
  width: 100%; /* Adjust the width as necessary */
  max-width: 800px; /* Maximum width for larger screens */
  padding: 12px 15px; /* Padding for the input */
  border: 1px solid #4CAF50; /* Border color */
  border-radius: 25px; /* Rounded corners */
  font-size: 16px; /* Font size */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for focus */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Focus Effect */
.search-bar:focus {
  border-color: #388E3C; /* Darker border on focus */
  box-shadow: 0 0 8px rgba(56, 142, 60, 0.5); /* Glow effect on focus */
  outline: none; /* Remove default outline */
}

/* Placeholder Text */
.search-bar::placeholder {
  color: #999; /* Placeholder color */
  
}

/* search end */

.book-image {
  width: 150px; /* Set width as desired */
  height: auto; /* Maintain aspect ratio */
  margin-left: 10px; /* Space between image and book details */
}


.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-modal-content {
  position: relative;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  background: transparent;
  border: none;
}

.author-bio {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.author-image {
  width: 50px; /* Adjust the size as needed */
  height: auto;
  border-radius: 50%; /* Make it circular */
  margin-right: 10px; /* Space between image and text */
}





/* Modal for bio image */
.bio-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.bio-image-modal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  margin: auto;
  text-align: center;
}

.bio-image-modal-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

.enlarged-bio-image {
  width: 100%;
  height: auto;
}


.book-graph {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9; /* Light background for contrast */
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/* Centering the select dropdown */
.search-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-bottom: 20px; /* Space below the search bar */
}

.category-dropdown {
  padding: 10px; /* Padding inside the dropdown */
  margin-left: 10px; /* Space between search input and dropdown */
  font-size: 16px; /* Font size */
  border: 1px solid #ccc; /* Border styling */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* Background color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: border-color 0.3s; /* Smooth transition for border color */
  width: 200px; /* Fixed width for better appearance */
}

/* Add responsiveness for smaller screens */
@media (max-width: 600px) {
  .category-dropdown {
    width: 100%; /* Make dropdown full width on small screens */
    margin-left: 0; /* Remove margin */
  }
}


.cart-container {
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 20px;
}

.checkout-btn {
  background-color: #28a745;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.checkout-btn:hover {
  background-color: #218838;
}


.header {
  display: flex;
  justify-content: space-between; /* Align items to space out */
  align-items: center; /* Center vertically */
  margin-bottom: 20px; /* Add some spacing below */
}

.ebook-image {
  width: 20%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease; /* Smooth scaling transition */
}

.ebook-image.clicked {
  transform: scale(1.2); /* Enlarge the image by 20% */
}



.view-cart-btn {
  align-items: center; 
  background-color: #28a745; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding for button */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  font-size: 16px; /* Font size */
}

.view-cart-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}


/* CSS for image pop-up */
.image-popup,
.bio-image-popup {
  position: fixed; /* Fixed positioning to overlay on top of the page */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for the image size */
  z-index: 1000; /* Ensure the pop-up is on top of other content */  
  padding: 20px; /* Padding around the image */
  border-radius: 10px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Shadow effect */
}

.popup-image {
  max-width: 90%; /* Responsive max width */
  max-height: 90vh; /* Responsive max height */
  border-radius: 5px; /* Optional: Rounded corners for the image */
}

.close {
  position: absolute; /* Position the close button */
  top: 5px; /* Position at the top */
  right: 5px; /* Position at the right */
  color: white; /* Close button color */
  font-size: 24px; /* Size of the close button */
  cursor: pointer; /* Pointer cursor for the close button */
  z-index: 1001; /* Ensure the close button is on top */
}
