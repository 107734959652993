/* General Styles */

.App {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-image: url('./images/panafrican500.jpg'); /*  background image */
  background-size: cover; /* image covers the entire container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the background image */
  
}

h1 {
  color: #4CAF50;
  font-size: 2.3rem;
  margin-bottom: 20px;
}
h2 {
  color: #ffffff;
  font-size: 200%;
  margin-bottom: 20px;
}

nav {
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background for nav */
  padding: 3px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

nav ul li a:hover {
  background-color: #3a9e40;
}

/* Responsive Styles */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  nav ul {
    padding-left: 0;
  }

  nav ul li a {
    font-size: 1rem;
    padding: 8px 15px;
  }
}

/* Route Content Styling */
.book-list,
.add-book-form {
  padding: 20px;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.book-list h3,
.add-book-form h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}


.logo img {
  max-width: 100px; /* Set a standard max-width for the logo */
  width: 100%; /* Ensures responsiveness */
  height: auto; /* Keeps the aspect ratio */
}
.App {
  text-align: center; /* Centers content in App */
}

.logo {
  margin: 20px auto; /* Centers the logo container */
}





/* Navigation Bar Styling */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #f5f5f5;
  padding: 1rem;
  flex-wrap: wrap;
}

.navbar button {
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navbar button:hover {
  background-color: #007bff;
  color: #fff;
}

.buttonRegister {
  background-color: #28a745;
  color: white;
}

.buttonRegister:hover {
  background-color: #218838;
}

buttonLogin {
  width: 250px;
}
buttonRegister{
  width: 250px;
}
founder-button{
  width: 250px;
}



/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 1rem;
  box-sizing: border-box;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Close button for modal */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.close-btn:hover {
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    gap: 0.5rem;
  }

  .modal-content {
    padding: 1.5rem;
  }

  .navbar button {
    font-size: 0.9rem;
    padding: 0.7rem 1rem;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.5rem;
  }

  .modal-content {
    padding: 1rem;
    max-width: 90%;
  }

  .navbar button {
    font-size: 0.8rem;
    padding: 0.6rem 0.9rem;
  }
}

/* Login Modal Specific Styling */
.login-modal-content {
  max-width: 400px;
  width: 100%;
  padding: 2rem;
}

.login-modal-content h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.login-modal-content input {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-modal-content button {
  width: 100%;
  padding: 0.8rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}

.login-modal-content button:hover {
  background-color: #0056b3;
}

/* Register Modal Specific Styling */
.register-modal-content {
  max-width: 400px;
  width: 100%;
  padding: 2rem;
}

.register-modal-content h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.register-modal-content input {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.register-modal-content button {
  width: 100%;
  padding: 0.8rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
}

.register-modal-content button:hover {
  background-color: #218838;
}

/* Founder Modal Specific Styling */
.founder-modal-content {
  max-width: 500px;
  width: 100%;
  padding: 2rem;
}

.founder-modal-content h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.founder-modal-content p {
  font-size: 1rem;
  line-height: 1.6;
}

.founder-modal-content button {
  padding: 0.8rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.founder-modal-content button:hover {
  background-color: #0056b3;
}








