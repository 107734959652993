PaymentManagement.css

.payment-management {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .payment-management h2 {
    text-align: center;
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .payment-management p {
    font-size: 1.1em;
    margin: 10px 0;
    color: #555;
  }
  
  .payment-management form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .payment-management input {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
  }
  
  .payment-management input:focus {
    border-color: #4CAF50;
  }
  
  .payment-management button {
    padding: 12px;
    font-size: 1.1em;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .payment-management button:hover {
    background-color: #0056b3;
  }
  
  .payment-management .notification {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    text-align: center;
  }
  