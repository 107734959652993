

.payment-form h2 {
    text-align: center; /* Center the heading */
    color: #333; /* Dark color for text */
}

.payment-form p {
    text-align: center; /* Center the amount display */
    font-weight: bold; /* Bold text */
    color: #2c3e50; /* Color for the amount */
}

.book-link{
display:none}




.payment-form form {
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
}

.payment-form label {
    margin-bottom: 5px; /* Space below the label */
    font-weight: bold; /* Bold label text */
}

.payment-form input[type="email"] {
    padding: 10px; /* Padding for input */
    margin-bottom: 15px; /* Space below the input */
    border: 1px solid #ccc; /* Input border */
    border-radius: 4px; /* Rounded corners for input */
    font-size: 14px; /* Font size for input */
    width: 95%;
}


/* PaymentForm.css */
.payment-heading {
    font-size: 24px;
    font-weight: bold;
    color: #4CAF50;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
  }
  