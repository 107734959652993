/* Container for the founder component */
.founder-container {   
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 60vh;
    max-width: 100%;
    margin: auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  /* Responsive layout for larger screens */
  @media (min-width: 768px) {
    .founder-container {
      flex-direction: row;
    }
  }
  
  /* Image styling */
  .founder-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }
  
  .founder-image {
    width: 180px;
    height: 180px;    
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out;
  }
  
  .founder-image:hover {
    transform: scale(1.05);
  }
  
  /* Text information styling */
  .founder-info {
    
    max-width: 200%;
    text-align: left;
    overflow-y: auto;
  }
  
  .founder-info h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .founder-info p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .founder-info ul {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
  }
  
  .founder-info ul li {
    font-size: 1rem;
    color: #444;
    padding: 0.5rem 1rem;
    background-color: #e3eaf2;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    transition: background-color 0.3s ease-in-out;
  }
  
  .founder-info ul li:hover {
    background-color: #d1d9e4;
  }
  
  .founder-info p strong,
  .founder-info ul li strong {
    color: #2b6777;
    font-weight: 700;
  }
  